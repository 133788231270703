#form-footer-content{
    padding-top: 5vh;
}

.form-footer-text-style{
    text-align: center;
    color: #a9a9a9;
    padding-bottom: 2vh;
    padding-top: 5vh;
    font-size: 14px;
}
#contact-list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
#contact-list li{
    padding-left: 0px !important; 
}
#contact-list li span{
    font-weight: 700;
    font-size: 0.8rem;
    align-items: start !important;    
}
#contact-list li span a{
    color: #f4f4f4;
    text-decoration: none;
}
#contact-list li span a:hover{
    font-weight: 500;
}

@media (max-width:1024px){
    #contact-list{
        grid-template-columns: 1fr;
    }
    
}

@media (max-width:550px){
    #form-footer-content{
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    #contact-list{
        grid-template-columns: 1fr;
    }
   
    .form-footer-text-style{
        font-size: 12px;
    }

}