 #signup-form{
     display: grid;
     grid-template-columns: 1fr;
     grid-gap: 20px;
     width: 50%;
     height: auto;
     max-height: 80vh;
     overflow:scroll;
 }
 #signup-form input{
     font-size: 12px;
 }
 .link-style {
     text-align: center;
     text-decoration: none;
 }
 .modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.success-text{
    color:green;
    font-size: 18px;
    font-weight: bold;
}

.paper {
    background-color: #fff;
    box-shadow:  3px 5px -1px rgba(0,0,0,0.2), 
                0px 5px 8px 0px rgba(0,0,0,0.14), 
                0px 1px 14px 0px rgba(0,0,0,0.12);
    
    padding: 40px;
}

 @media (max-width:1024px){
    #signup-form{
        height: auto;
        width: 60%;
    }
    #signup-form input{
        font-size: 12px;
    }
}

@media (max-width:550px){
    #signup-form{
        height:auto;
        width: auto;
    }
}