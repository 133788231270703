
.link-form div:nth-child(2){
    display: inline-block !important;
}
.link-form div:nth-child(1){
    display: inline-block !important;
}
.link-form div:nth-child(3){
    display: inline-block !important;
}
.link-form{
    justify-self: end;
    display: flex;
}
@media (max-width:1045px){
    .link-form button:last-child{
        margin-top: 1vh;
    }
    .link-form{
        justify-self: center;
    }
}
