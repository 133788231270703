:root {
    --primary-color:#9d0055 ;
    --grey-color:#9d9d9d; 
    --dark-grey:#3F3D56;
  }
  html{
      scroll-behavior: smooth;
  }

#intro{
    margin-bottom: 10vh;
}
.list-style{
    list-style: none;
}
.sub-header-img{
    background: #000 ;
    display: inline-block; 
    margin-top: 1vh
}
.sub-header-img img{
    width: 20vh;
}

#header{
    display: grid;
    grid-template-columns: 1fr 1fr 3fr ;
    justify-items: center;
    align-items: center;
    margin-top: 8vh;
    grid-gap:2vw;
}
#wrapper #header,#placement-page #header{
    grid-template-columns: 1fr  3fr ;
}


.link-form a:hover{
text-decoration: none;
}

.header_intro{
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    display: flex; 
    justify-content: center; 
    flex-direction: column;
}

.header_intro .download_links{   
    margin-top: 15px;
}

.header_intro .download_button{
    font-size: 1rem;
    padding-left: 10px;

}

.highlight li{
    padding: 10px 0px;
    list-style-type: none;
}   

#showcase{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-gap: 10vw;
    margin-top: 10vh;
}
#showcase img{
    width:40vw;
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); 
}
#showcase img:hover {
    -webkit-filter: grayscale(0);
    filter: none;
  }
.bg-style{
    background-color: #f4f4f4;
    height: 90vh;
    display: grid;
    align-items: center;
}
#reg-form-home .btn-style1 > div{
    display: inline-block;
   
}
#reg-form-home .btn-style1  div:first-child{
   margin-right: 5px;
   
}

#admission{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5vh;
    width: 80%;
    margin: 0 auto;    
}
.p-Style{
    font-size: 12px;
    color: #9d9d9d;
    padding: 10px
}
#admission > div{
    /* border: 2px solid var(--primary-color); */
    color: var(--dark-grey);   
    text-align: center;
    background-color: #fff; 
    border-radius: 5px;
     
}
#admission > div:hover{
    background-color: #f7f7f7;
    transition: background-color 1s ease;
   
}

#admission a{
    color:#9d0055;
    text-decoration: none;
 }
#admission img{
    height: 5vh;
    text-align: center;
}
#admission li span{
   font-family:  'Lato', sans-serif;
   color: var(--dark-grey);
   line-height: 1.6rem;
   /* font-weight: 700; */
}
#instruction-detl{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-top: 5vh;
}
.section-detl1 ul,.section-detl2 ul{
    list-style: none;   
}
.section-detl1,.section-detl2{
    padding-bottom: 20px;   
}
.sub-info{
    font-weight:500;
    font-style:italic;
}
.placement-nav{
    border:2px solid #9d0055;
    padding:10px;
    display:inline-block;margin-bottom: 5vh;
    
}
.placement-nav a{
    text-decoration: none;
    color: #9d0055;
    
}
.avatar-img > div{
    margin:0 auto;
}
.placement-nav:hover{
    color:#fff;
    background-color: #9d0055;
}
.placement-nav:hover a{
    color:#fff;
    background-color: #9d0055;
}

#instruction .placement-nav{
    text-align: center;
    margin: 0 auto;
}

.text-style{
    line-height: 2rem;
    color: rgba(0,0,0,0.5);
}

.text-style-highlight{
    line-height: 2rem;
    color:#9d0055;
}

.text-justify{
    text-align: justify;
}

.text-center{
    text-align: center;
}

.text-bold{
    font-weight: bold;
}


.title-style{
    color:var(--dark-grey);
}
#contact-container{
    height: auto;
}
#alumni-stories{
    width: 100vw;
    height: 100vh;
    position: relative;    
    color:#fff;
    background-image: url('../images/confrence.jpg');   
    background-position: center;
    background-repeat:no-repeat;
    background-size:cover; 
    background-color: #002626; 
    background-blend-mode: multiply;
    /* scroll-snap-align: start; */
    
}

.stories-header-style{
    text-align: center;    
    z-index: 1;
    position: relative;    
}
.stories-header-style h1{
    color: #fff;
    font-weight: 700;
    z-index: 100;
}
#placement-dt{
    display: grid;
    grid-template-columns: 2fr 3fr;
    margin-top: 10vh;
    border: 1px solid #f4f4f4;
    padding: 40px;
    grid-gap: 20px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-bottom:10vh;
}

.company-logos{
    display: grid;
    grid-template-columns: 1fr;
}
.company-logos img{
    width: 150px;
    height: 50px !important;
    object-fit: contain;
}
.row1,.row2,.row3,.row4{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap:15px
}
.line-style{
    text-align: center;
}
.line-style img{
    height: 70vh;
}
.stories-text-style{
    letter-spacing: 0.025rem;
    line-height: 2rem;
}
.carousel-btn{
    position:relative;
     top:25vh;
     color:#fff;
     border:none;
     background:none
}
.carousel-btn:hover{
    cursor: pointer;
}
.btn-left{
    left:7vw;
}
.btn-right{
    left:90vw;
}
.card-head-style{
    background: #000;
    padding: 30px;
    color: #fff
}
.card-head-style svg{
padding-right: 1rem ;
margin-bottom: -5px;
}

.download_links{
    text-align: center;
    align-items: center;    
}

.download_button{
    margin: 2px;
    border:2px solid #9d0055;   
    padding: 10px;    
    text-decoration: none;
    color: #9d0055;
}

.download_button:hover{
    color: #fff;
    background-color: #9d0055;
}
/* #status-form{
    display: grid;
    grid-gap: 2rem;
    margin-top: 1vh;
} */
#status-form{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    margin-top: 1vh;
}
h4.sub-title{    
    font-weight: bold;
    padding-left: 0px;
    margin-bottom: 0px;
} 
/*table styling*/

.ranklist_title{
    margin: 20px 0px;
    font-size: 1.5rem;    
}

.ranklist_container{
    display: flex;
    font-size: 1.25rem;
    justify-content: space-between;
    padding: 10px;
    font-weight: bold;
}

.feelist{
    padding-left: 3vw;
    list-style: disc !important;
}
 

.feetable_container {    

    display: flex;
    flex-direction: row;
    color: #3f3d56;
    margin-top: 2vh;    
    justify-items: center;
    vertical-align: top;
    justify-content: center;
}

.feetable_container table{
    max-width: 50vw;
    margin-right: 10px;
}

.feetable {
    padding: 5px;
    border: 1px solid black;
    border-collapse: collapse;
    width: 45%;
}


@media (max-width:1210px){
    #reg-form-home .btn-style1  div:first-child{
        margin-bottom: 1vh;        
     }

}

@media (max-width:1024px){
    #header{
        grid-template-columns:1fr 1fr 2fr;
        grid-gap: 2vw;
    }
    #wrapper #header,#placement-page #header{
        grid-template-columns: 1fr ;
    }    
    
    #admission{
        grid-template-columns:1fr; 
    }
    .bg-style{
        height: auto;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    #placement-dt{
       grid-template-columns: 1fr;
       margin-top: 0vh !important;
    }
    .line-style{
        display: none;
    }
    .title-style1{
        margin-top: 0vh !important;
    }
    .carousel-btn{
        top:20vh;
    }
   
}

@media (max-width:550px){
    #header{
        grid-template-columns:1fr;
    }
    #showcase,#admission,#placement-dt{
        grid-template-columns:1fr;  
    }
    .logo img{
        width: 80% !important;
    }
    .logo{
        text-align: center;
    }
    .company-logos img{
        width: 100px !important;
    }
    #placement-dt{
        padding: 0px;
        border: none;
        box-shadow: none;
    }
    .link-form{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
    }
    .line-style{
        display: none;
    }
    .row1,.row2,.row3,.row4{
        grid-template-columns: 1fr 1fr;
        box-shadow: none;
    }
    #intro,.bg-style{
        height: auto;
    }
    .bg-style{
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    .stories-header-style{
        padding-top: 5vh;
    }
    #alumni-stories{
        height: auto;
        font-size: 14px;
        padding-bottom: 5vh;
        margin-top: 25vh;
    }
    .carousel-btn{
        top:40vh;
    }
    .btn-right{
        left: 80vw;
    }
    .MuiTabs-scrollButtonsDesktop {
        /* display: none; */
        display: inline-block !important;
    }
    #admission{
        width: 95% !important;
    }
    #instruction-container{
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .download_button{
        display:flex;
    }

    #showcase img {
        width:100%;
        object-fit: cover;
    }

    .feetable_container{
        flex-direction: column;
    }

    .feetable_container table {
        margin-top: 20px;
        width: 100%;
        max-width: 100vw;

    }
}

/*****************************
*	horizontal news ticker css start
******************************/

.ticker-wrapper-h{
	display: flex;	
	position: relative;
	overflow: hidden;
	border: 1px solid #1c6547;
}

.ticker-wrapper-h .heading{
	background-color: #1c6547;
	color: #fff;
	padding: 5px 10px;
	flex: 0 0 auto;
	z-index: 1000;
}
.ticker-wrapper-h .heading:after{
	content: "";
	position: absolute;
	top: 0;
	border-left: 20px solid #1c6547;
	border-top: 17px solid transparent;
	border-bottom: 15px solid transparent;
}


.news-ticker-h{
	display: flex;
	margin:0;
	padding: 0;
	padding-left: 90%;
	z-index: 999;
	
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-name: tic-h;
	animation-duration: 30s;
	
}
.news-ticker-h:hover { 
	animation-play-state: paused; 
}

.news-ticker-h li{
	display: flex;
	width: 100%;
	align-items: center;
	white-space: nowrap;
	padding-left: 20px;
}

.news-ticker-h li a{
	color: #212529;
	font-weight: bold;
}

@keyframes tic-h {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
/*****************************
*	horizontal news ticker css end
******************************/