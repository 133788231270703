/* .code {
    margin: 0px;
    top: auto;
    right: 20px;
    bottom: 30vh;
    left: auto;
    position: fixed;
}; */

@media(max-width:1024px){
    .code img{
        width:100% !important;
    }
}