.section-styles{
   margin-top:2vh;
   margin-bottom: 2vh;
   line-height: 1.8rem;
}
.section-styles p{
    color:rgba(0,0,0,0.5);
}
.section-styles li{
    list-style: none;
    color: #3f3d56;
}
.section-styles h4{
    color:#3f3d56;
    margin-bottom: 2vh;
}
.footer-pages-header{
    margin-top:8vh;
    margin-bottom:8vh;
}
.margin-b{
    margin-bottom: 15vh;
}
.dark-line{
    height:8px;
    width:48px;
    background:#9d0055;
    margin-bottom:2vh;
}