html *{
    font-family: 'Lato', sans-serif;
    margin:0px;
    padding: 0px;
}

.App{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    scroll-snap-type: y proximity !important;
   
}

.title-style1{    
    color: var(--dark-grey);
    margin-top: 10vh;
    text-align: center;
}
.header-style{
    text-align: center;
    color: #fff;
    padding-bottom: 2vh;
    padding-top: 5vh;
    font-weight: 600;
}
.logo img{
    width: 20vw;
    float: left;
}
.logo-email img{
    width: 15vw;
    float: left;
}

