#online-instructions{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    width: 80%;
    max-height: 80vh;
    overflow:scroll;
}
#online-instructions ul{
    list-style: none;
  
}
.main-list li{
    align-items: flex-start;
}
.sub-list li{
    list-style-type: lower-latin;
    padding-left: 20px;
    font-weight: 600;
}
.mute-text{
    color: #9d9d9d;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paper {
    background-color: #fff;
    box-shadow:  3px 5px -1px rgba(0,0,0,0.2), 
                0px 5px 8px 0px rgba(0,0,0,0.14), 
                0px 1px 14px 0px rgba(0,0,0,0.12);
    
    padding: 40px;
}


@media (max-width:1024px){
    #online-instruction{
        height: auto;
        width: 30%;
    }
    
}

@media (max-width:550px){
    #online-instruction{
        height:auto;
        width: auto;
    }
}