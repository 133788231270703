/*variables*/
:root {
    --primary-color:#9d0055 ;
    --grey-color:#9d9d9d; 
    --dark-grey:#3F3D56;
  }
.first-page{
    display: grid;
    grid-template-columns:  1fr 1fr 1fr;
    justify-items: center;
    margin-top: 10vh;
}
#wrapper{
    background-color: #f4f4f4;    
}
.height{
    height:100vh;
}
.column-height{
    height:20vh;
}

.text-upper{
    text-transform: uppercase;
}
.form-style{
    display:grid;    
    grid-template-columns:1fr 1fr;
    grid-column-gap: 80px; 
    grid-row-gap: 20px;  
    font-size: 14px; 
    color: var(--grey-color);

}
.form-style label{
    font-family: 'Poppins', sans-serif;
    color: var(--grey-color);
}
.labelStyle span{
    font-size: 0.8rem !important;
}

.container-style{    
    max-width: 80% !important;
    margin: 0 auto;
    padding: 30px; 
    background-color: #fff;
    padding-top: 0px; 
    padding-left: 0px;
    padding-right: 0px;
}
#basic-details,#paymentDetails,#document-details,#courses_preference{
    margin-top: 40px;
}
/*previous ,next btn styles */
.btn-style{
    margin-top: 8vh;
   display: flex;
   justify-content: flex-end;
    padding-bottom: 5vh;
     
}
.btn-style button{
    margin-left: 10px;
    margin-right: 10px;
}
.btn-style1{
    text-align: center;
    margin-top: 5vh;
}
.img-style1{
    text-align: center;
}
.img-style1 img{
    height: 20vh;
}
input{
    font-size: 12px;
}

/*title styles */
.sub-title1{
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-top: 8vh;
}


.text-style1{
    color: var(--dark-grey);
}

.highlight{
    color: var(--primary-color);
    font-weight: bold;
}
.highlightwarning{
    color:red;
    font-weight: bold;
    float: left;
    vertical-align:middle;
   
}
.textblink{
    color: red;
    font-weight: bold;
    font-size: larger;
    animation: textblinker .7s linear infinite;
    float: left;
}  
@keyframes textblinker {
    0%{
        opacity: 0;
      }
    50%{
    opacity: 0;
    }
    100%{
    opacity: 1;
    }
}

#gender{
    display: inline;   
}
#radio-style{
    border-bottom: 1px solid #0000006b;
}
.icon-style {
   color:var(--grey-color);    
}
.icon-style:focus{
    color: black;
}
.permanent-add,.communication-add,.board-exam-dt,.keam-exam-dt,.guardian-dt{
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
}
#courses_preference{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 80px;
    grid-row-gap:20px ;
   
}
.error-text{
    color:red;
    margin-top: 5px;
    
}
.form-fields > div{
    width:100%
}
.input-inline{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.input-inline >div:nth-child(2){
    margin-left: 10px;
}



/* document upload btn style*/
  
.btn-file {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid  var(--grey-color);
    display: flex;   
    text-align: center;
}
.btn-file-status{
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}
.file-status-to-uploaded{
    color: green;
}
.file-status-to-upload{
    color: orange;
}
/*.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
    
}
.btn-file button{
    padding: 0px;
}*/
.placeholder-style{
 font-size: 12px;
 position: absolute;
 left: 2px;
 
}
/*progessbar active cirlce color*/
.MuiStepIcon-root.MuiStepIcon-completed,.MuiStepIcon-root.MuiStepIcon-active{
    color:var(--primary-color) !important;
}
.summary-style{
    padding-top: 20px;
    padding-bottom: 20px;
}
.summary-style ul li{
    list-style: none; 
    padding-top:10px; 
}
.summary-style ul li:nth-child(odd){
   font-weight: 300;    
}
.summary-style ul li:nth-child(even){
    font-weight: 700;    
 }
.summary-style h4{   
    /* border: 1px solid var(--primary-color); */
    color: var(--primary-color);
    padding: 10px;
    margin-bottom: 10px;
    font-weight: 700;
}
.summaryHeader{
    display: flex;
    justify-content: space-between;
}

ul ul{
    padding-left: 20px !important;
    opacity: 0.7;
}
.summary-list-style{
    display: grid;
    grid-template-columns: 1fr 1fr;    
}
#instruction ul li{
    line-height: 2rem;
}
.profile-img-style span,.profile-img-style img{
    margin-bottom: 1vh;
}

.fabProgress {
    color: green;
    position: absolute;
    top: -6;
    left: -6;
    z-index: 1;
}
.label-style{
    padding:5px;
    border: 1px solid #9d9d9d;
    font-size: 0.7rem;  
}

.allocation_section{
    padding: 20px;
}

.allocation_section .title-style1{
    margin-top: 10px;
}

.allocation_section .button_bar{
   margin-top: 10px;
   justify-content: space-between;
   display: flex;    
}

.allocation_accept_btn{
    background-color: green;
}

.allocation_section_form{
    display:flex;
    justify-content: space-evenly;
}

@media (max-width:550px){
    .img-style1{
        display: none;
    }
    .first-page{
        grid-template-columns: 1fr;
    }
    .text-style1{
        text-align: center;
    }
    .btn-style1{
        text-align: center;
        margin-bottom: 3vh;
    }
    .form-style{
        grid-template-columns: 1fr;
    }
    .btn-style {
        text-align: center;
        margin-top: 3vh;
        display: grid;
        grid-template-columns: 1fr;

    }
    .btn-style button{
        margin-top: 10px;
    }
    .column-height{
        height:auto
    }
    .input-inline{
        grid-template-columns: 1fr;
    }
    #courses_preference{
        grid-template-columns: 1fr;
    }
    .container-style{
        max-width: 100% !important;
    }  

}
  
  