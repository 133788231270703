.email-list-style {
    color: #9d0055;
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 10px;
}
#contact-list-link a{
   color:#9d0055;
   text-decoration: none;
}
#contact-list-link a:hover{
    font-weight: 700;
}