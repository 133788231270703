.reset-psw{    
    display:grid;
    grid-template-columns:50vw;
    grid-gap:30px;
    padding:20px;
    padding-top:100px;
   
}

@media (max-width:550px){
    .reset-psw{    
       
        grid-template-columns:1fr;
    }
    
}