.placement-btn-style{
margin-left:0px !important;
margin-bottom: 2vh;

}
.placement-btn-style button{
    color:#fff;
    font-weight: 700;
    background-color: #9d0055;
    border-color: #9d0055 !important;
}
