#footer-content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 5vh;
}
.social-media-icons  img{
    width: 44px;
    height: 44px;
    float: right;
    padding: 10px;

}
.social-media-icons  img:first-child{
    width: 46px;
    height: 46px;
}
.footer-text-style{
    text-align: center;
    color: #a9a9a9;
    padding-bottom: 2vh;
    padding-top: 5vh;
    font-size: 14px;
}
.footer-page-link-style{
    display: grid;
    justify-items: end;
    grid-gap: 12px;
}
.footer-page-link-style a{
    text-decoration: none;
    color: #f4f4f4;
    font-size: 0.8rem;
    font-weight: 600;  

}
.footer-page-link-style a:hover{
    color:#d4d4d4;
}

@media (max-width:1024px){
    
}

@media (max-width:550px){
    .footer-page-link-style{
        justify-items: start; 
    }
    #footer-content{
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .social-media-icons  img{
        float:none;
    }
    .footer-text-style{
        font-size: 12px;
    }

}